import pie from './pie.js'
import bar from './bar.js'


export default {
    name: "test",

    components: {
        bar,
        pie
    },


    data() {
        return {
            rows: [[
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false }
            ],
            [
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false }
            ],
            [
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false }
            ],
            [
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false }
            ],
            [
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false },
                { text: "You can modify this text like the vaadin grid pro", edition: false }
            ]
            ]

        }
    }
}